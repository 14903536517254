<template>
    <div class='equipment'>
        <!-- <div  class="breadcrumb"></div> -->
        <div class='e_content'>
            <h2 class="title">
                <div class="title_icon"></div>
               诱导屏信息
            </h2>
            <el-form :model="formInline" :rules="rules2" ref="ruleForm2" label-width="160px" class="demo-ruleForm" style="width: 600px;">
                <el-form-item :label="$t('searchModule.Induced_screen_name')" prop="screenName">
                    <el-input :maxlength="20" :disabled='isEdit' type="text" v-model.trim="formInline.screenName" auto-complete="off"></el-input>
                </el-form-item>
                <el-row :gutter="20">
                    <el-col :span="4">
                        <el-form-item label="分辨率:"></el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="屏幕高度" prop="screenHeight">
                            <el-input v-model="formInline.screenHeight" :disabled='isEdit'></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="屏幕宽度" prop="screenWidth">
                            <el-input v-model="formInline.screenWidth" :disabled='isEdit'></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="诱导屏系统编码" prop="screenCode">
                    <el-input :maxlength="20" :disabled='isEdit' v-model.trim="formInline.screenCode"></el-input>
                </el-form-item>
                <el-form-item label="SN" prop="equipmentModel">
                    <el-input :maxlength="30" type="text" v-model.trim="formInline.sn" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="诱导屏类型">
                    <el-select v-model.trim="formInline.category"
                               size="15">
                        <el-option label="一级"
                                   value="1"></el-option>
                        <el-option label="二级"
                                   value="2"></el-option>
                        <el-option label="三级"
                                   value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="诱导屏位置" prop="location">
                    <el-input rows="4" resize="none"
                              type="textarea"
                              placeholder="请输入信息"
                              v-model="formInline.location"
                              maxlength="100"
                              show-word-limit></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitData">{{ $t('button.preservation') }}</el-button>
                    <el-button @click="$router.go(-1)">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
const regN = /^[0-9]*$/
    export default {
        data () {
            return {
                isEdit: false,
                oldName: '',
                oldCode: '',
                equipmentId: '',
                equipmentType: [],
                equipmentModel: [],
                tenantList: [],
                traceList: [],
                formInline: {
                    screenCode: '',
                    screenName: '',
                    sn: '',
                    screenHeight: '',
                    screenWidth: '',
                    location: '',
                    category: ''
                },
                rules2: {
                    screenName: [
                        {
                            required: true,
                            message: '请输入诱导屏名称',
                            trigger: 'blur'
                        }
                    ],
                    screenCode: [
                        {
                            required: true,
                            message: '请输入诱导屏系统编码',
                            trigger: 'blur'
                        }
                    ],
                    screenWidth: [{
                        required: true,
                        message: '请输入宽度',
                        trigger: 'blur'
                    }, {
                        validator: (rule, value, callback) => {
                        if (value == 0) {
                            callback(new Error("宽度不能为0"));
                        } else if (!regN.test(value)) {
                            callback(new Error("只能输入数字"));
                        } else {
                            callback();
                        }
                        },
                        trigger: "blur"
                    }],
                    screenHeight: [{
                        required: true,
                        message: '请输入高度',
                        trigger: 'blur'
                    }, {
                        validator: (rule, value, callback) => {
                        if (value == 0) {
                            callback(new Error("高度不能为0"));
                        } else if (!regN.test(value)) {
                            callback(new Error("只能输入数字"));
                        } else {
                            callback();
                        }
                        },
                        trigger: "blur"
                    }]
                },
                screenId: ''
            };
        },
        methods: {
            getOptions () {
                let url = '/acb/2.0/equipment/getAddOption';
                this.$axios.get(url).then(res => {
                    if (res.state == 0) {
                    this.equipmentType = res.value['equipmentType'];
                    this.traceList = res.value['traceTag']
                } else {
                    this.$alert(res.desc, this.$t('pop_up.Tips'), {
                        confirmButtonText: this.$t('pop_up.Determine')
                    })
                }
            })
            },
            getScreenDetail () {
                this.$axios.get('/acb/2.0/ydpScreen/query', {
                    data: {
                        screenId: this.screenId,
                            ...this.formInline
                    }
                }).then(res => {
                    if (res.state == 0) {
                    this.formInline = res.value;
                    this.formInline.category = String(res.value.category);
                } else {
                    this.$alert(res.desc, this.$t('pop_up.Tips'), {
                        confirmButtonText: this.$t('pop_up.Determine')
                    })
                }
            })
            },
            /* 商户名称的下拉框 */
            getTenantList () {
                this.$axios.get('/acb/2.0/operation/nameList').then(res => {
                    if (res.state == 0) {
                    this.tenantList = res.value;
                } else {
                    this.$alert(res.desc, this.$t('pop_up.Tips'), {
                        confirmButtonText: this.$t('pop_up.Determine')
                    });
                }
            })
            },
            getBrand () {
                let url = '/acb/2.0/brand/selectList'
                this.$axios.get(url).then(res => {
                    if (res.state == 0) {
                    this.equipmentModel = res.value;
                } else {
                    this.$alert(res.desc, this.$t('pop_up.Tips'), {
                        confirmButtonText: this.$t('pop_up.Determine')
                    })
                }
            })
            },
            submitData () {
                let data = {};
                if (this.isEdit) {
                    data = {
                        equipmentId: this.$route.query.equipmentId,
                            ...this.formInline
                }
                } else {
                    data = this.formInline;
                }
                let url = this.isEdit ? '/acb/2.0/ydpScreen/update' : '/acb/2.0/ydpScreen/add'
                let method = 'post';
                this.$refs['ruleForm2'].validate((valid) => {
                    if (valid) {
                        if (this.formInline.sn && !/^[0-9a-zA-Z]+$/.test(this.formInline.sn)) {
                            this.$alert('SN仅持字母和数字', this.$t('pop_up.Tips'), {
                                confirmButtonText: this.$t('pop_up.Determine')
                            })
                            return;
                        }
                        this.$axios[method](url, {
                            data: data
                        }).then(res => {
                            if (res.state == 0) {
                            this.$alert('操作成功！', this.$t('pop_up.Tips'), {
                                confirmButtonText: this.$t('pop_up.Determine')
                            }).then(() => {
                                this.$router.go(-1);
                        })
                        } else {
                            this.$alert(res.desc, this.$t('pop_up.Tips'), {
                                confirmButtonText: this.$t('pop_up.Determine')
                            })
                        }
                    })
                    } else {
                        return false;
            }
            });
            }
        },
        created () {
            this.getOptions();
            this.getBrand();
            this.getTenantList();
            if (this.$route.query.equipmentId) {
                this.isEdit = true;
            }
        },
        mounted () {
            if (this.$route.query.screenId) {
                this.isEdit = true
                this.screenId = this.$route.query.screenId
                this.getScreenDetail();
            }
        }
    }
</script>
<style scoped>
    .equipment{
       background-color: #fff;
    }
    .equipment .e_content{
        border: 1px solid #c0ccda;
        border-radius: 4px;
        min-height: 550px;
    }
    .equipment .e_content .demo-ruleForm{
        width:350px;
    }
    .breadcrumb {
        height: 35px;
    }
    .title {
        padding-top: 10px;
        padding-left:20px;
    }
</style>
